<template>
    <div>
        <b-row cols="3" class="mb-5">
            <b-col>
                <game-info
                    :game="game"
                    :teamName="teamName"
                    :cash="cash"
                    class="mt-2"
                    style="max-height: 50vh; overflow-y: scroll"
                >
                </game-info>
            </b-col>
            <b-col v-for="graph in graphs" :key="graph.id">
                <div class="card mt-2">
                    <div class="card-header">
                        <span>
                            {{ graph.id }}
                        </span>
                        <div class="float-right d-flex">
                            <data-export-button
                                :name="graph.id"
                                :data="
                                    addDays(
                                        graph.data.stats.map(removeNestedProps)
                                    )
                                "
                            >
                            </data-export-button>
                            <div class="btn" @click="openGraph(graph)">
                                <b-icon
                                    icon="arrows-fullscreen"
                                    font-scale="0.85"
                                ></b-icon>
                            </div>
                        </div>
                    </div>

                    <component
                        class="card-body"
                        :ref="graph.id"
                        :is="graph.class + '-plots'"
                        :data="graph.data"
                        :maintainAspectRatio="true"
                    >
                    </component>
                </div>
            </b-col>
        </b-row>
        <b-modal
            ref="showPlotsModal"
            size="xl"
            :title="selectedUnitTitle"
            ok-only
            centered
            hide-footer
            body-class="p-1 mb-2"
        >
            <component
                v-if="selectedPlantUnitPlotsComponent"
                :is="selectedPlantUnitPlotsComponent"
                :data="selectedPlantUnitStats"
            >
            </component>
        </b-modal>
    </div>
</template>

<script>
import { addDays } from '../index'
import DataExportButton from '../Units/Plots/DataExportButton.vue'

export default {
    components: { DataExportButton },
    props: {
        game: {
            type: Object,
        },
        teamName: {
            type: String,
        },
        cash: {
            type: Number,
        },
    },
    data() {
        return {
            selectedUnitTitle: '',
            selectedPlantUnitStats: undefined,
            selectedPlantUnitPlotsComponent: undefined,
        }
    },
    methods: {
        getUnitStats(unit, id) {
            if (!this.game || !this.game.plant || !this.game.plant.stats) {
                return null
            }

            let stats = []

            for (var s of this.game.plant.stats) {
                var data = s.units[id]

                if (unit.class == 'shipment') {
                    data = { ...s.plant, ...data }
                }

                stats.push(data)
            }

            return stats
        },

        addGraph(graphs, unitName) {
            const unit = this.game.plant.units[unitName]

            switch (unit.class) {
                case 'matcher':
                    this.addGraph(graphs, unit.params.warehouse)
                    break
                case 'warehouse':
                    this.addGraph(graphs, unit.params.supplier)
                    break
            }

            graphs.push({
                id: unitName,
                class: unit.class,
                data: {
                    unit: unit,
                    stats: this.getUnitStats(unit, unitName),
                },
            })
        },
        removeNestedProps(obj) {
            let prunedObj = {}
            for (const prop in obj) {
                if (typeof obj[prop] !== 'object') {
                    prunedObj[prop] = obj[prop]
                }
            }
            return prunedObj
        },
        addDays: addDays,
        openGraph(graph) {
            this.selectedUnitTitle = graph.id
            this.selectedPlantUnitPlotsComponent = graph.class + '-plots'
            this.selectedPlantUnitStats = graph.data

            this.$refs.showPlotsModal.show()
        },
    },
    computed: {
        graphs() {
            if (!this.game || !this.game.plant) {
                return []
            }

            let graphs = []

            for (const pipelineName of Object.keys(this.game.plant.pipeline)) {
                const pipeline = this.game.plant.pipeline[pipelineName]
                for (const unit of pipeline) {
                    this.addGraph(graphs, unit.unit)
                }
            }

            return graphs
        },
    },
}
</script>
