<template>
    <div class="pt-4 ml-5 mr-5">
        <div class="float-right">
            <b-spinner
                variant="primary"
                class="mr-3"
                style="width: 1.25rem; height: 1.25rem"
                v-if="update_btn_loading"
            >
            </b-spinner>
            <b-button
                size="sm"
                variant="danger"
                @click="restartGame"
                v-if="async"
                class="mr-2"
                :disabled="loading || update_btn_loading"
            >
                Restart
            </b-button>
            <b-button
                size="sm"
                variant="primary"
                @click="loadTeam"
                :disabled="loading || update_btn_loading"
            >
                <b-icon icon="arrow-clockwise" font-scale="1"></b-icon> Update
            </b-button>
        </div>

        <b-tabs content-class="mt-3 player-tab-layout">
            <b-tab title="Play" @click="onPlayTabSelect" active>
                <factory
                    :style="mainStyle"
                    ref="factory"
                    :player="true"
                    :game.sync="game"
                    @game-reload="loadTeam"
                    :teamName="teamName"
                    :cash="cash"
                >
                </factory>

                <div
                    class="d-flex justify-content-center mt-5"
                    :style="spinnerStyle"
                >
                    <b-spinner variant="primary"></b-spinner>
                </div>
            </b-tab>

            <b-tab title="Team">
                <div class="container text-muted" :style="mainStyle">
                    <div>
                        <p>Players:</p>

                        <ol v-if="!!team">
                            <li v-for="user in team.users" :key="user.id">
                                <i>{{ user.name }}</i>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <p>Actions:</p>

                        <b-table
                            class="mt-3 w-100 min-vh-100 table table-sm text-xsmall font-weight-light"
                            :items="actions.table.data"
                            :fields="actions.table.fields"
                            :busy="loading"
                            small
                            select-mode="single"
                            sticky-header
                            head-variant="light"
                            ref="actionTable"
                            show-empty
                            responsive
                        >
                            <template #empty>
                                <div class="text-center text-muted my-2">
                                    There are no records to show
                                </div>
                            </template>

                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    Loading...
                                </div>
                            </template>

                            <template #cell(description)="data">
                                <i>{{ data.value }}</i>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div
                    class="d-flex justify-content-center mt-5"
                    :style="spinnerStyle"
                >
                    <b-spinner variant="primary"></b-spinner>
                </div>
            </b-tab>

            <b-tab title="Graphs">
                <graphs :game.sync="game" :teamName="teamName" :cash="cash">
                </graphs>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import ApiService from '@/services/api.service'
import { GameStatus, formatDateString } from '@/views'
import Vue from 'vue'
import Factory from '../components/Designer/Factory.vue'
import Graphs from '../components/Designer/Game/Graphs.vue'

export default {
    components: { Factory, Graphs },
    data() {
        return {
            game: null,
            team: null,
            loading: true,
            update_btn_loading: true,
            actions: {
                table: {
                    data: [],
                    fields: ['date', 'user', 'unit', 'description'],
                },
            },
        }
    },
    methods: {
        loadTeam() {
            this.loading = this.game === null // only the first load should trigger the spinner
            this.update_btn_loading = true
            let t = this

            ApiService.getTeam({ id: this.$route.params.teamId }).then(
                ((response) => {
                    let team = response.data.data

                    this.teamName = team.name
                    this.cash = team.cash

                    if (team.game.status == GameStatus.INITIALIZED) {
                        this.$bvModal
                            .msgBoxOk('The game has not started yet...', {
                                title: 'Information',
                                centered: true,
                            })
                            .then((success) => {
                                this.$router
                                    .push({ name: 'game' })
                                    .catch((err) => {})
                            })
                    } else {
                        const oldActionsCount =
                            this.team && this.team.actions
                                ? this.team.actions.length
                                : -1
                        this.team = team

                        if (
                            team.game.status == GameStatus.ASYNC &&
                            team.plant === null
                        ) {
                            // if we are in async mode and the plant is not set yet, we assume that the game needs to be restarted
                            this.restartGame()
                            return
                        }

                        if (team.cash === null) {
                            setTimeout(this.loadTeam.bind(this), 500)
                            return
                        } else {
                            this.loading = false
                            this.update_btn_loading = false
                        }

                        this.game = {
                            plant: team.plant,
                            team: team,
                            ...team.game,
                        }

                        this.actions.table.data = []

                        this.team.actions.forEach((action) => {
                            this.actions.table.data.push({
                                date: action.created_at,
                                user: action.user.name,
                                unit: action.meta.unit_id,
                                description: action.meta.log,
                            })
                        })

                        if (
                            oldActionsCount != -1 &&
                            this.team.actions.length > oldActionsCount
                        ) {
                            for (
                                let i = oldActionsCount;
                                i < this.team.actions.length;
                                i++
                            ) {
                                const action = this.team.actions[i]
                                this.$bvToast.toast(
                                    `${action.user.name} applied action "${action.meta.log}" to "${action.meta.unit_id}"`,
                                    {
                                        title: 'Action was added',
                                        toaster: 'b-toaster-top-center',
                                        variant: 'warning',
                                        solid: true,
                                    }
                                )
                            }
                        }
                    }
                }).bind(this),
                (error) => {
                    this.$bvModal
                        .msgBoxOk(error.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.$router
                                .push({ name: 'game' })
                                .catch((err) => {})
                        })
                }
            )
        },
        onPlayTabSelect() {
            this.$refs.factory.reloadVisNetwork()
        },
        restartGame() {
            this.loading = true
            this.update_btn_loading = true
            ApiService.restartAsyncGame(this.team.id).then((resp) => {
                setTimeout(() => {
                    location.reload()
                }, 1000)
            })
        },
    },
    mounted() {
        this.loadTeam()
        setInterval(this.loadTeam.bind(this), 10000) // update ever 10 seconds
    },
    created() {
        this.teamName = ''
        this.cash = NaN
    },
    computed: {
        mainStyle() {
            return this.loading ? 'display: none !important;' : ''
        },
        spinnerStyle() {
            return !this.loading ? 'display: none !important;' : ''
        },
        async() {
            if (this.game) {
                return this.game.status === GameStatus.ASYNC
            } else {
                return false
            }
        },
    },
}
</script>
<style>
.player-tab-layout {
    min-height: 820px;
}
</style>
